import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setLoading,
  notificationFail,
  notificationSuccess,
  notificationClear,
} from "../slices/notificationSlice";
import {
  setAllUIUser,
  setAllUiUserDataShareScope,
  setUiUserDataShareScope,
  setEmulateUserList
} from "../slices/refreshSlice";
import dotnetApiClient from "../../config/dotnetApiClient";

const handleLogout = () => {
  const isIframe = window.self !== window.top;
  const hostname = window.location.hostname;
  let redirectUrl = '';
  localStorage.clear();
  sessionStorage.clear();

  if (isIframe) {
    if (hostname === 'localhost') {
      redirectUrl = 'http://localhost:3001';
    } else if (hostname === 'my-dev.usapath.com') {
      redirectUrl = 'https://my-dev.legalcaseapp.com';
    } else {
      redirectUrl = 'https://my.legalcaseapp.com';
    }
    if (window.top) {
      window.top.location.href = redirectUrl;
    }
  } else {
    if (hostname === 'localhost') {
      redirectUrl = 'http://localhost:3000';
    } else if (hostname === 'my-dev.usapath.com') {
      redirectUrl = 'https://my-dev.usapath.com';
    } else {
      redirectUrl = 'https://my.usapath.com';
    }
  }
}

export const refreshUserData: any = createAsyncThunk(
  "publicUpdateUserData",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      dispatch(notificationClear());
      const response = await dotnetApiClient().post(`/refresh/${_request}`);

      dispatch(setLoading(false));
      dispatch(notificationSuccess(`Update data for user: ${_request} successfully!`));

      return response;
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout()
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const getAllUIUsers: any = createAsyncThunk(
  "getAllUIUserData",
  async (_, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await dotnetApiClient().get(`UiUser/`);
      dispatch(setLoading(false));

      if (response?.data) {
        dispatch(setAllUIUser(response?.data));
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout()
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const postUiUserDataShareScope: any = createAsyncThunk(
  "postUiUserDataShareScope",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      dispatch(notificationClear());
      const response = await dotnetApiClient().post(`/UiUserDataShareScope`, _request);

      dispatch(setLoading(false));
      dispatch(notificationSuccess("Emulate user created successfully!"));
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout()
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const deleteUiUserDataShareScope: any = createAsyncThunk(
  "deleteUiUserDataShareScope",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      dispatch(notificationClear());
      await dotnetApiClient().delete(`/UiUserDataShareScope/${_request?.id}`);

      dispatch(getUiUserDataShareScope());
      dispatch(getAllUiUserDataShareScope());
      dispatch(setLoading(false));
      dispatch(notificationSuccess("Emulation deleted successfully!"));
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout()
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const getUiUserDataShareScope: any = createAsyncThunk(
  "getUiUserDataShareScope",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      dispatch(notificationClear());
      const response = await dotnetApiClient().get(`/UiUserDataShareScope`);

      dispatch(setLoading(false));
      dispatch(setUiUserDataShareScope(response?.data));
      // dispatch(notificationSuccess("Emulate user created successfully!"));
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout()
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const getAllUiUserDataShareScope: any = createAsyncThunk(
  "getAllUiUserDataShareScope",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      dispatch(notificationClear());
      const response = await dotnetApiClient().get(`/UiUserDataShareScope/all`);

      dispatch(setEmulateUserList(response));

      dispatch(setLoading(false));
      dispatch(setAllUiUserDataShareScope(response?.data));
      // dispatch(notificationSuccess("Emulate user created successfully!"));
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout()
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ClickAnalyticsPlugin } from "@microsoft/applicationinsights-clickanalytics-js";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

import { useAppDispatch, useAppSelector } from "../../store/store";
import Navicons from "../../assets/images/Navicons.svg";
import SideLogo from "../../assets/images/logo-white.png";
import Signouticon from "../../assets/images/Signouticon.svg";
import Admin from "../../assets/images/Admin.svg";
import {
  getProgressData,
  getSummaryData,
} from "../../store/thunk/documentThunk";
import {
  addUserForm,
  getSetSectionId,
  getSingleForm,
  replenish,
} from "../../store/thunk/formThunk";
import {
  anonymousUser,
  checkDependant,
  checkDependantGroup,
  checkDependantQuestion,
  checkDependantQuestionTitle,
  checkDependantSummary,
  displayIcon,
  structureArray,
  setAppInsightsData,
} from "../../helper/service";
import ValidateFormJson from "../stepper/validateFormJson";

import {
  setAllNodeData,
  setAllSectionList,
} from "../../store/slices/flowFormSlice";
import lawFirmLogos from "../../config/lawFirmLogos";
import { getLogoForClient } from "../../store/thunk/billingThunk";

export default function SidebarComponent(props: any) {
  const { setIsSidebarVisible } = props;
  const role: any = localStorage.getItem('role') || localStorage.getItem('userType');
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth0();
  const [isIframe, setIsIframe] = useState(false);
  const [loading, setLoading] = useState(true);

  let isAdmin =
    localStorage.getItem("userType")?.toLocaleLowerCase() == "admin"
      ? true
      : false;
  let uiFlowJsonId = localStorage.getItem("uiFlowJsonId")
    ? localStorage.getItem("uiFlowJsonId")
    : "";
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData") || "{}")
    : null;

  const lawyerData: any = JSON.parse(
    localStorage.getItem("lawyerData") || "{}"
  );

  const [sectionList, setSectionList] = useState<any[]>([]);
  const [nodeData, setNodeData] = useState<any[]>([]);
  const [formData, setFormData] = useState<any>({});
  const [currentSection, setCurrentSection] = useState<any>();
  const [isProgressbarVisible, setIsProgressbarVisible] = useState(false);
  const [isUserDisable, setIsUserDisable] = useState(true);

  const [adminClicked, setAdminClicked] = useState<any>(false);
  const [value, setValue] = useState<any>("Application");
  const dispatch: any = useAppDispatch();
  const paymentLoading = useAppSelector(
    (state: any) => state.notificationReducer.paymentLoading
  );
  const [sidebarLogoURL, setSidebarLogoURL] = useState("");
  const [isInIframe, setIsInIframe] = useState(false);

  useEffect(() => {
    if (window.top !== window.self) {
      setIsInIframe(true);
    }
  }, [location]);

  const sectionId = useAppSelector((state: any) => state.formReducer.sectionid);
  const currentData = useAppSelector(
    (state: any) => state.formReducer.currentData
  );
  const { sidebarLogo } = useAppSelector(
    (state: any) => state.billingReducer
  );

  const currentQuestionList = useAppSelector(
    (state: any) => state.formReducer.currentQuestionList
  );

  const { authConfig } = useAppSelector((state: any) => state.authReducer);

  let localData: any = localStorage.getItem("bootstrapData");
  const bootstrapData: any = JSON.parse(localData);

  const { getSignleFormData, uiFormPayload } = useAppSelector(
    (state: any) => state?.formReducer
  );

  const { progressData } = useAppSelector(
    (state: any) => state.documentReducer
  );

  const [openSections, setOpenSections] = useState<any[]>([]);

  const billingEntityId = localStorage.getItem('billingEntityId');
  // const logoSrc: any = billingEntityId ? lawFirmLogos[billingEntityId] : null;

  useEffect(() => {
    if (sidebarLogo !== null) {
      setSidebarLogoURL(sidebarLogo);
    }
  }, [sidebarLogo]);

  const toggleSection = (selectedId: any) => {
    if (openSections.includes(selectedId)) {
      // If the clicked section is already open, close it
      setOpenSections(openSections.filter((id) => id !== selectedId));
    } else {
      // If the clicked section is not open, close all other sections and open the clicked one
      setOpenSections([selectedId]);
    }
  };

  useEffect(() => {
    dispatch(getProgressData());
  }, []);

  useEffect(() => {
    setCurrentSection(sectionId?.id);
  }, [sectionId?.id]);

  useEffect(() => {
    if (currentSection && !openSections.includes(currentSection)) {
      toggleSection(currentSection);
    }
  }, [currentSection]);

  useEffect(() => {
    if (!anonymousUser()) {
      setFormData({ ...uiFormPayload });
    }
  }, [uiFormPayload]);

  useEffect(() => {
    if (currentData && Object.keys(currentData || {}).length) setFormData({ ...currentData });
  }, [currentData]);

  useEffect(() => {
    if (uiFlowJsonId) { // Ensure uiFlowJsonId is not null or undefined
      dispatch(getSingleForm({ uiFlowJsonId }));
    }
  }, [dispatch, uiFlowJsonId]);

  useEffect(() => {
    if (bootstrapData)
      if (
        bootstrapData &&
        !bootstrapData?.isDisabled &&
        !bootstrapData?.isDeleted
      ) {
        setIsUserDisable(false);
      }
  }, [bootstrapData]);

  useEffect(() => {
    const detectIframe = () => {
      try {
        if (window.self !== window.top) {
          setIsIframe(true);
        } else {
          setIsIframe(false);
        }
      } catch (e) {
        setIsIframe(true);
      }
      setLoading(false);
    };

    detectIframe();
  }, []);

  const convertIntoUiJson = (jsonData: any) => {
    try {
      let orderCounts: any = [];

      return (
        !!jsonData &&
        jsonData.length > 0 &&
        jsonData.map((val: any) => {
          let childList = jsonData
            .filter((row: any) => val.id === row.parentNode)
            .map((row: any) => row.id);
          let order = orderCounts.filter((row: any) => val.parentNode === row);
          orderCounts.push(val.parentNode);

          return {
            id: val.id,
            parentNode: val?.parentNode,
            order: (!!order?.length ? order?.length : 0) + 1,
            children: childList,
          };
        })
      );
    } catch (error) {
      alert("json is not correct.");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!getSignleFormData?.flowJson) return;

      const getValue = JSON.parse(getSignleFormData.flowJson);
      if (!getValue?.nodes || getValue?.nodes?.length === 0) return;

      const getJsonData = await convertIntoUiJson(getValue?.nodes);
      const mergedObj = getValue?.nodes?.flatMap((flowJsonData: any) =>
        getJsonData
          ?.filter(
            (getJsonDataItem: any) => flowJsonData.id === getJsonDataItem.id
          )
          .map((matchingItem: any) => ({ ...matchingItem, ...flowJsonData }))
      );
      setNodeData(mergedObj);

      const tempArr = structureArray(mergedObj);

      const sectionData = tempArr.filter(
        (nodesData: any) =>
          nodesData?.data?.nodeType?.toLowerCase() === "section" ||
          (nodesData?.data?.nodeType?.toLowerCase() === "text" &&
            !!nodesData.data.nodeTitle)
      );

      console.log("sectionData ===>s", sectionData);

      if (sectionData?.length > 0) {
        setSectionList(sectionData);
      }
    };

    fetchData();
  }, [getSignleFormData]);

  useEffect(() => {
    dispatch(setAllSectionList(sectionList));
  }, [sectionList]);

  useEffect(() => {
    dispatch(setAllNodeData(nodeData));
  }, [nodeData]);

  useEffect(() => {
    if (location.pathname && sectionList.length) {
      const summaryID = location?.state?.summaryId;
      const dynamicPathName = decodeURI(location.pathname);
      const splittedArray = dynamicPathName?.split("/");
      const mainId = splittedArray[2];
      // Check if mainId is defined
      if (mainId) {
        const selectedSection = sectionList?.find(
          (section) => section?.data?.nodeTitle === mainId
        );

        const currNodeTitle = sectionId?.sectionNodeData?.filter(
          (val: any) => val?.id != sectionId?.id
        );
        // Check if selectedSection is defined

        if (selectedSection && currNodeTitle?.data?.nodeTitle != mainId) {
          const findIndex = sectionList?.findIndex(
            (ele) => ele?.data?.nodeTitle === mainId
          );
          onSectionClick(selectedSection, findIndex, summaryID || "");
        }
      }
    }

    if (location.pathname == "/home") {
      setOpenSections([]);
    }
    window.history.replaceState(null, "", location.pathname);
  }, [location.pathname, sectionList, location?.state]);

  const onSectionClick = (section: any, index: any, summaryId: any = "") => {
    const summary = section?.childItem?.filter((val: any) =>
      checkDependantSummary(val, formData)
    );

    if (summaryId) {
      const activeSummary = section?.childItem?.filter(
        (val: any) =>
          val?.id == summaryId && checkDependantSummary(val, formData)
      );

      const activeSummaryIndex = section?.childItem
        ?.filter((val: any) => checkDependantSummary(val, formData))
        .findIndex(
          (val: any) =>
            val?.id == summaryId && checkDependantSummary(val, formData)
        );

      onClickSummary(
        section?.id,
        index,
        activeSummary[0]?.id,
        activeSummaryIndex
      );
    } else {
      toggleSection(section?.id);
      onClickSummary(section?.id, index, summary[0]?.id, 0);
    }
  };

  const onClickSummary = (
    id: any,
    index: any,
    summaryId: any,
    summary_index: any,
    close: any = false,
    summary?: any
  ) => {
    const currentSecData = sectionList?.find((data: any) => data?.id === id);

    // Track data for App Insights
    if (summary !== undefined) {
      setAppInsightsData(summary?.data?.nodeTitle, authConfig);
    }

    const sumarry = currentSecData?.childItem?.filter(
      (data: any) =>
        data?.id === summaryId &&
        data?.data?.nodeTitle?.toLowerCase() === "summary"
    );

    if (sumarry.length) {
      saveUpdatedField(true);
    } else {
      saveUpdatedField();
    }

    dispatch(
      getSetSectionId({
        id: id || "",
        summaryId: summaryId || "",
        sectionNodeData: nodeData,
        sectionList: sectionList,
        index: index,
        summaryIndex: summary_index,
      })
    );
    if (close) {
      setIsSidebarVisible(false);
    }
  };

  const renderSections = (sidebarItem: any[]) => {
    const renderedSections: any = [];
    let stopRendering = false;

    // Render eligibility sections
    sidebarItem.forEach((section, sectionIndex) => {

      const allowRender = checkDependant(section, formData);

      if (!allowRender) {
        return
      }

      // Section rendering conditions (based on nodeType)
      if (section?.data?.nodeType?.toLowerCase() === "text") {
        stopRendering = true;
      }

      if (!stopRendering) {
        const sectionElement = (
          <li className="sidebar-list sideBarShow" id={section.id} key={section.id}>
            <div
              className={`cursor-pointer flex items-center justify-between px-2 py-2.5 text-white rounded-lg hover:text-gray-100 group`}
              onClick={() => {
                setAppInsightsData(section?.data?.nodeTitle, authConfig);

                // Always navigate, regardless of eligibility checks
                navigate(`/eligibility-check/${section?.data?.nodeTitle}`);

                // Conditionally toggle section, but not impacting redirection
                if (decodeURI(location.pathname) === `/eligibility-check/${section?.data?.nodeTitle}`) {
                  toggleSection(section?.id);
                }
              }}
            >
              <span className="flex items-center">
                <img
                  className={`${section?.id === sectionId?.id &&
                    decodeURI(location.pathname) === `/eligibility-check/${section?.data?.nodeTitle}`
                    ? "opacity-100"
                    : "opacity-20"
                    }`}
                  src={displayIcon(section?.data?.icon)}
                  alt={section?.data?.icon}
                />
                <span
                  className={`ml-3 text-[#6B7280] text-[14px] ${section?.id === sectionId?.id ? "text-white" : ""
                    }`}
                >
                  {checkDependantQuestionTitle(section, formData) ||
                    section?.data?.nodeTitle}
                </span>
              </span>
            </div>

            {openSections.includes(section.id) && (
              <ul className="space-y-2">
                {section?.childItem
                  ?.filter((val: any) => checkDependantSummary(val, formData))
                  .map((summary: any, summary_index: any) => {
                    const arr = section?.childItem?.filter((val: any) =>
                      checkDependantSummary(val, formData)
                    );
                    if (
                      summary_index !== 0 &&
                      arr[summary_index - 1]?.childItem?.length
                    ) {
                      const groupList = arr[summary_index - 1]?.childItem
                        .filter((val: any) => checkDependantGroup(val, formData))
                        .map((val: any) =>
                          val.data?.properties?.shouldContinue === false
                            ? false
                            : true
                        );
                      if (groupList.includes(false)) {
                        stopRendering = true;
                      }
                    }
                    if (!stopRendering) {
                      return (
                        <li key={summary.id} className="sideBarShow">
                          <div
                            className={`cursor-pointer flex items-center justify-between px-2 ml-3 py-2 my-1 text-white rounded-lg hover:text-gray-100 group`}
                            onClick={() =>
                              onClickSummary(
                                section?.id,
                                sectionIndex,
                                summary?.id,
                                summary_index,
                                true,
                                summary
                              )
                            }
                          >
                            <span className="flex items-center whitespace-nowrap overflow-hidden">
                              <span
                                className={`text-[#6B7280] text-[14px] overflow-hidden text-ellipsis  ${summary?.id === sectionId?.summaryId
                                  ? "text-white"
                                  : ""
                                  }`}
                              >
                                {checkDependantQuestionTitle(
                                  summary,
                                  formData
                                ) || summary?.data?.nodeTitle}
                              </span>
                            </span>
                          </div>
                        </li>
                      );
                    }
                  })}
              </ul>
            )}
          </li>
        );
        renderedSections.push(sectionElement);
      }
    });
    return [...renderedSections];
  };

  const handleNavClick = () => {
    setOpenSections([]);
    saveUpdatedField();
    dispatch(
      getSetSectionId({
        id: "",
        summaryId: "",
        questionGroupId: "",
        sectionNodeData: [],
        sectionList: [],
        index: "",
        summaryIndex: "",
      })
    );
    setIsSidebarVisible(false);
  };

  const saveUpdatedField = (isSummary: boolean = false) => {
    let queList = [...currentQuestionList];
    const unSavedFormValues = JSON.parse(
      sessionStorage.getItem("unSavedFormValues") || "{}"
    );
    const updatedFormValues = JSON.parse(
      sessionStorage.getItem("updatedFormValues") || "{}"
    );

    let list = queList.filter((val: any) =>
      checkDependantQuestion(val, unSavedFormValues)
    );
    let err = ValidateFormJson({ formData: unSavedFormValues, list });

    const filteredObj2 = Object.keys(updatedFormValues).reduce(
      (acc: any, key: any) => {
        if (!err.hasOwnProperty(key)) {
          acc[key] = updatedFormValues[key];
        }
        return acc;
      },
      {}
    );

    // Optimise API call if form has been updated then call these apis
    if (Object.keys(updatedFormValues).length !== 0) {
      dispatch(
        addUserForm({
          form_id: 1073,
          form_data: JSON.stringify(filteredObj2),
        })
      ).then((res: any) => {
        dispatch(replenish());

        if (isSummary) {
          dispatch(getSummaryData());
        }
      });
    } else {
      if (isSummary) {
        dispatch(getSummaryData());
      }
    }
  };

  const handleLogout = async (e: any) => {
    const isIframe = window.self !== window.top;
    const hostname = window.location.hostname;
    let redirectUrl = '';
    localStorage.clear();
    sessionStorage.clear();

    if (isIframe) {
      if (hostname === 'localhost') {
        redirectUrl = 'http://localhost:3001';
      } else if (hostname === 'my-dev.usapath.com') {
        redirectUrl = 'https://my-dev.legalcaseapp.com';
      } else {
        redirectUrl = 'https://my.legalcaseapp.com';
      }
      if (window.top) {
        window.top.location.href = redirectUrl;
      }
    } else {
      if (hostname === 'localhost') {
        redirectUrl = 'http://localhost:3000';
      } else if (hostname === 'my-dev.usapath.com') {
        redirectUrl = 'https://my-dev.usapath.com';
      } else {
        redirectUrl = 'https://my.usapath.com';
      }
      // Shutdown Intercom for non-iframe users
      var w = window as any;
      var ic = w.Intercom;
      ic("shutdown");
      window.location.href = redirectUrl;
    }

    // Clear App Insights context
    const browserHistory = createBrowserHistory({ basename: "" } as any);
    var reactPlugin = new ReactPlugin();
    const clickPluginInstance = new ClickAnalyticsPlugin();
    const clickPluginConfig = {
      autoCapture: true,
    };

    const configObj = {
      connectionString: authConfig?.appInsightsConnectionString,
      extensions: [clickPluginInstance, reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
        [clickPluginInstance.identifier]: clickPluginConfig,
      },
      enableAutoRouteTracking: true,
    };
    const appInsights = new ApplicationInsights({ config: configObj });
    appInsights.clearAuthenticatedUserContext();

    // Trigger the logout process with the proper redirect URL
    await logout({ logoutParams: { returnTo: redirectUrl } });
  };


  const handleAdminCLick = (newValue: any) => {
    localStorage.setItem("buttonClicked", newValue);
    setValue(newValue);
    if (newValue === "Admin") {
      navigate("/admin");
    } else {
      navigate("/home");
    }
  };

  const showSidebar = () => {
    if (!anonymousUser() && !isUserDisable) {
      return true;
    } else if (isIframe) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <aside
      id="sidebar-multi-level-sidebar"
      className="fixed top-0 left-0 z-[1200] bg-[#111827] h-full transition-transform -translate-x-full sm:translate-x-0"
      aria-label="Sidebar">
      {loading ? (
        <Box className="sidebar-logo">Loading...</Box>
      ) : (
        <Box className="sidebar-logo">
          {
            isInIframe ? sidebarLogoURL ? <img
              src={sidebarLogoURL}
              alt="Law Firm Logo" className="sideLogo" />
              : <></> : <img src={SideLogo} alt="Logo" className="sideLogo" />
          }
        </Box>
      )}
      {paymentLoading ? <Box className="paymentLoader" /> : ""}
      {/* All Menus */}
      <>
        {showSidebar() && (
          <div
            className={`${isAdmin ? "h-[calc(100%_-_179px)]" : "h-[calc(100%_-_165px)]"
              } px-[8px] overflow-x-hidden overflow-y-auto custom-scroll`}
          >
            <ul className="space-y-2 font-medium p-0">
              <li className="sidebar-list sideBarShow">
                <NavLink
                  to={"/home"}
                  key={"Home"}
                  onClick={handleNavClick}
                  className={`flex items-center justify-between px-2 py-2.5 text-white rounded-lg hover:text-gray-100 group`}
                >
                  <span className="flex items-center">
                    <img
                      src={Navicons}
                      alt="Navicons"
                      className={`${location.pathname === "/home" ? "opacity-100" : "opacity-20"
                        }`}
                    />
                    <span className="ml-3 text-[#6B7280] text-[14px]">Home</span>
                  </span>
                </NavLink>
              </li>
              {!isIframe &&
                localStorage.getItem("buttonClicked") &&
                localStorage.getItem("buttonClicked") === "Admin" && (
                  <>
                    <li className="sidebar-list sideBarShow">
                      <NavLink
                        to={"/admin"}
                        key={"admin"}
                        onClick={() => navigate("/admin")}
                        className={`flex items-center justify-between px-2 py-2.5 text-white rounded-lg hover:text-gray-100 group`}
                      >
                        <span className="flex items-center">
                          <span className="text-[#6B7280] text-[14px]">Admin Home</span>
                        </span>
                      </NavLink>
                    </li>
                    <li className="sidebar-list sideBarShow">
                      <NavLink
                        to={"/users"}
                        key={"users"}
                        onClick={() => navigate("/users")}
                        className={`flex items-center justify-between px-2 py-2.5 text-white rounded-lg hover:text-gray-100 group`}
                      >
                        <span className="flex items-center">
                          <span className="text-[#6B7280] text-[14px]">User</span>
                        </span>
                      </NavLink>
                    </li>
                    <li className="sidebar-list sideBarShow">
                      <NavLink
                        to={"/form"}
                        key={"form"}
                        onClick={() => navigate("/form")}
                        className={`flex items-center justify-between px-2 py-2.5 text-white rounded-lg hover:text-gray-100 group`}
                      >
                        <span className="flex items-center">
                          <span className="text-[#6B7280] text-[14px]">Form</span>
                        </span>
                      </NavLink>
                    </li>
                    <li className="sidebar-list sideBarShow">
                      <NavLink
                        to={"/refresh"}
                        key={"refresh"}
                        onClick={() => navigate("/refresh")}
                        className={`flex items-center justify-between px-2 py-2.5 text-white rounded-lg hover:text-gray-100 group`}
                      >
                        <span className="flex items-center">
                          <span className="text-[#6B7280] text-[14px]">Refresh</span>
                        </span>
                      </NavLink>
                    </li>
                    <li className="sidebar-list sideBarShow">
                      <NavLink
                        to={"/form_emulation"}
                        key={"form_emulation"}
                        onClick={() => navigate("/form_emulation")}
                        className={`flex items-center justify-between px-2 py-2.5 text-white rounded-lg hover:text-gray-100 group`}
                      >
                        <span className="flex items-center">
                          <span className="text-[#6B7280] text-[14px]">User Form Emulation</span>
                        </span>
                      </NavLink>
                    </li>
                    <li className="sidebar-list sideBarShow">
                      <NavLink
                        to={"/add-new-form"}
                        key={"add-new-form"}
                        onClick={() => navigate("/add-new-form")}
                        className={`flex items-center justify-between px-2 py-2.5 text-white rounded-lg hover:text-gray-100 group`}
                      >
                        <span className="flex items-center">
                          <span className="text-[#6B7280] text-[14px]">Add New Form</span>
                        </span>
                      </NavLink>
                    </li>
                    <li className="sidebar-list sideBarShow">
                      <NavLink
                        to={"/b2b-entities"}
                        key={"b2b-entities"}
                        onClick={() => navigate("/b2b-entities")}
                        className={`flex items-center justify-between px-2 py-2.5 text-white rounded-lg hover:text-gray-100 group`}
                      >
                        <span className="flex items-center">
                          <span className="text-[#6B7280] text-[14px]">B2B Entities</span>
                        </span>
                      </NavLink>
                    </li>
                  </>
                )}

              {(isIframe || (!isIframe && localStorage.getItem("buttonClicked") !== "Admin")) &&
                renderSections(sectionList)}
            </ul>
          </div>
        )}
        {/* Bottom Menus */}
        <ul className="fixed min-h-[85px] flex flex-col justify-center bg-[#111827] px-[16px] w-full bottom-0 space-y-2 font-medium p-0 border-t-gray-800 border-t border-solid">
          {paymentLoading ? <Box className="paymentLoader" /> : ""}
          {isAdmin && !sessionStorage?.getItem("Usapath-Emulate-UserId") && (
            <li
              className="sidebar-list sideBarShow w-full"
              onClick={() =>
                handleAdminCLick(
                  value === "Application" ? "Admin" : "Application"
                )
              }
              style={{ cursor: "pointer" }}
            >
              <span className=" flex items-center justify-between  px-2 py-2.5  text-white rounded-lg hover:text-gray-100 group">
                <img src={Admin} alt="Admin" />
                <span className="flex-1 ml-3 ml-3 text-[#6B7280] text-[14px]">
                  {localStorage?.getItem("buttonClicked") === "Admin"
                    ? "Application"
                    : "Admin"}
                </span>
              </span>
            </li>
          )}

          {!anonymousUser() && (
            <li
              className="sidebar-list sideBarShow w-full"

            >
              <span className="signout-email">{isIframe ? lawyerData?.user?.email : userData?.email}</span>
              {(isAdmin && !sessionStorage?.getItem("Usapath-Emulate-UserId") || !['lawyer', 'Admin'].includes(role)) && <span className=" flex items-center justify-between  px-2 py-2.5  text-white rounded-lg hover:text-gray-100 group" onClick={handleLogout}
                style={{ cursor: "pointer" }}>
                <img src={Signouticon} alt="Signouticon" />
                <span className="flex-1 ml-3 ml-3 text-[#6B7280] text-[14px]">
                  SignOut
                </span>
              </span>}
            </li>
          )}
        </ul>
      </>
    </aside>
  );
}
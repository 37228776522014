import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { logErrorFunction, logFunction } from "../../helper/service";

const IframeListener: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.addEventListener('message', (event) => {

      // Check the message type
      if (event.data.type === 'UPDATE_USER_ID') {
        const bootstrapData = localStorage.getItem('bootstrapData');
        const data = JSON.parse(bootstrapData || "")
        // Update localStorage with the user ID
        localStorage.setItem('uiFlowJsonId', data?.uiFlowJsonId
        );
      }
    });
  }, [window]);

  useEffect(() => {
    if (window.self !== window.top) {
      const iframeToken = localStorage.getItem('iframeAccessToken');
      if (iframeToken) {
        localStorage.setItem('accessToken', iframeToken);
        setIsLoading(false);
        navigate("/home");
      } else {
        window.parent.postMessage({ type: 'iframeLoaded' }, '*');

        const handleMessage = (event: MessageEvent) => {
          const { authToken, user, role, logoUrl, billingEntityId, lawyer_data } = event.data;
          if (authToken) {
            try {
              localStorage.setItem('accessToken', authToken);
              localStorage.setItem('lawyerData', JSON.stringify(lawyer_data));
              localStorage.setItem('role', role);
              if (logoUrl) {
                localStorage.setItem('logoUrl', logoUrl);
              }
              if (billingEntityId) {
                localStorage.setItem('billingEntityId', billingEntityId);
              }
              setIsLoading(false);
              navigate("/home");
            } catch (error) {
              logErrorFunction('Error saving data to localStorage:' + error);
            }
          } else {
            logFunction('Auth token is undefined or not provided.');
          }

          if (user && role === 'lawyer') {
            try {
              sessionStorage.setItem('Usapath-Emulate-UserId', user.id);
              sessionStorage.setItem('Usapath-Emulate-UserName', user.name ?? '');
            } catch (error) {
              logErrorFunction('Error saving Usapath-Emulate-UserId to sessionStorage:' + error);
            }
          } else {
            logFunction('User data is undefined or not provided.');
          }
        };

        window.addEventListener('message', handleMessage);

        return () => {
          window.removeEventListener('message', handleMessage);
        };
      }
    } else {
      setIsLoading(false);
      navigate("/home");
    }
  }, [navigate]);

  return isLoading ? <div>Loading...</div> : null;
};

export default IframeListener;
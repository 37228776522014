import { useAuth0 } from "@auth0/auth0-react";
import { createBrowserHistory } from "history";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ClickAnalyticsPlugin } from "@microsoft/applicationinsights-clickanalytics-js";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../store/store";
import SideLogo from "../../assets/images/logo-white.png";
import Signouticon from "../../assets/images/Signouticon.svg";
import { useEffect, useState } from "react";
import lawFirmLogos from "../../config/lawFirmLogos";

export default function VerifySidebarComponent() {
  const { logout } = useAuth0();
  const location = useLocation();
  const role : any = localStorage.getItem('role') || localStorage.getItem('userType');
  
  const { authConfig } = useAppSelector((state: any) => state.authReducer);
  const [isInIframe, setIsInIframe] = useState(false);
  const billingEntityId = localStorage.getItem('billingEntityId');
  const [sidebarLogoURL, setSidebarLogoURL] = useState("");
  const { sidebarLogo } = useAppSelector(
    (state: any) => state.billingReducer
  );

  const logoSrc = billingEntityId ? lawFirmLogos[billingEntityId] : null;

  useEffect(() => {
    if (window.top !== window.self) {
      setIsInIframe(true);
    }
  }, [location]);

  useEffect(() => {
    if (sidebarLogo !== null) {
      setSidebarLogoURL(sidebarLogo);
    }
  }, [sidebarLogo]);

  const handleLogout = (e: any) => {
    const browserHistory = createBrowserHistory({ basename: "" } as any);
    var reactPlugin = new ReactPlugin();
    const clickPluginInstance = new ClickAnalyticsPlugin();
    const clickPluginConfig = {
      autoCapture: true,
    };

    // Application Insights Configuration
    const configObj = {
      connectionString: authConfig?.appInsightsConnectionString,
      extensions: [clickPluginInstance, reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
        [clickPluginInstance.identifier]: clickPluginConfig,
      },
      enableAutoRouteTracking: true,
    };

    const appInsights = new ApplicationInsights({ config: configObj });
    appInsights.clearAuthenticatedUserContext();

    localStorage.clear();
    sessionStorage.clear();

    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData") || "{}")
    : null;

  return (
    <aside
      id="sidebar-multi-level-sidebar"
      className="fixed top-0 left-0 z-[1200] bg-[#111827] h-full transition-transform -translate-x-full sm:translate-x-0"
      aria-label="Sidebar"
    >
      <div className="pt-6 pb-[18px] 2xl:pb-[36px] mx-[24px]">
        {
          isInIframe ? sidebarLogoURL ? <img
            src={sidebarLogoURL}
            alt="Law Firm Logo" className="sideLogo" />
            : <></> : <img src={SideLogo} alt="Logo" className="sideLogo" />
        }
      </div>

      <ul className="fixed min-h-[85px] flex flex-col justify-center bg-[#111827] px-[16px] w-full bottom-0 space-y-2 font-medium p-0 border-t-gray-800 border-t border-solid">
        <li
          className="sidebar-list sideBarShow w-full"
          onClick={handleLogout}
          style={{ cursor: "pointer" }}
        >
          <span className="signout-email">{userData?.email}</span>
          {!['lawyer', 'Admin'].includes(role) && <span className=" flex items-center justify-between  px-2 py-2.5  text-white rounded-lg hover:text-gray-100 group" onClick={handleLogout}
          style={{ cursor: "pointer" }}>
            <img src={Signouticon} alt="Signouticon" />
            <span className="flex-1 ml-3 ml-3 text-[#6B7280] text-[14px]">
              SignOut
            </span>
          </span>}
        </li>
      </ul>
    </aside>
  );
}
